<template>
  <div>
    <b-row class="mb-2">
      <b-col
        col
        lg="6"
      >
        <h4> Editar Time : {{ itemData.nome }}</h4>
      </b-col>
      <b-col
        v-if="itemData.imagem"
        col
        class="text-right"
        lg="6"
      >
        <img
          :src="itemData.imagem"
          height="50"
          alt="Icone do time"
        >
      </b-col>
    </b-row>
    <hr>
    <b-form>
      <b-row>

        <!-- Nome do time -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Nome Time"
            label-for="nome_time"
          >
            <b-form-input
              id="nome_time"
              v-model="itemData.nome"
              placeholder="Informe o nome do time"
            />
          </b-form-group>
        </b-col>

        <!-- Definição do Time -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Definição do Time"
            label-for="definicao-time"
          >
            <b-form-input
              id="definicao-time"
              v-model="itemData.definicao"
              name="definicao_time"
              trim
              placeholder="Informe a definição do time"
            />
          </b-form-group>

        </b-col>

        <!-- Integrantes -->
        <b-col cols="12">

          <b-form-group
            label="Integrantes do time"
            label-for="integrantes"
          >

            <select-com-pesquisa
              v-model="itemData.users"
              placeholder="Escolha os Integrantes"
              url="/users"
              :multiple="true"
              :disabled="false"
              :itens-para-edicao="itemData.users"
              @selecionados="tratarEventoUsuariosSelecionados"
            />

          </b-form-group>
        </b-col>

        <!-- imagem  -->
        <b-col cols="12">
          <b-form-group
            label="Icone do Time"
            label-for="Icone Time"
          >
            <div class="d-flex justify-content-start mt-2">
              <div class="d-block">
                <b-form-file
                  ref="fileInput"
                  label="Imagem"
                  accept="image/jpeg, image/png"
                  label-for="imagem"
                  browse-text="Procurar"
                  placeholder="Escolha uma imagem"
                  drop-placeholder="Escolha uma imagem"
                  @change="handleFileChange"
                />
              </div>
              <div
                v-if="selectedFile"
                class="d-flex justify-content-start ml-2 align-items-center"
              >
                <b-img
                  thumbnail
                  :src="previewUrl"
                  alt="Imagem"
                  :style="{ width: '50px', height: '50px' }"
                />
                <small class="ml-2">Pré visualização da imagem</small>
              </div>
            </div>
          </b-form-group>
        </b-col>

      </b-row>

    </b-form>
    <!-- Action Buttons -->
    <b-col class="text-right pr-0">
      <b-button
        variant="outline-secondary"
        type="button"
        :to="{ name: 'times' }"
      >
        Voltar
      </b-button>
      <b-button
        type="button"
        variant="success"
        class="mb-1 mb-sm-0 mr-0 ml-sm-1"
        @click="editarItem(itemData)"
      >
        Salvar
      </b-button>
    </b-col>
  </div>
</template>

<script>

import {
  BButton, BCol, BForm, BFormFile, BFormGroup, BFormInput, BImg, BRow,
} from 'bootstrap-vue'
import store from '@/store'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  components: {
    SelectComPesquisa,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormFile,
    BImg,
  },

  data() {
    return {
      selectedFile: null,
      itemData: {
        nome: '',
        definicao: '',
        imagem: '',
        imagemSelected: '',
        users: [],
      },

    }
  },

  created() {
    this.getTime()
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0]
      this.selectedFile = file
      this.itemData.imagemSelected = file

      if (file) {
        const reader = new FileReader()
        reader.onload = () => {
          this.previewUrl = reader.result
        }
        reader.readAsDataURL(file)
      } else {
        this.previewUrl = null
      }
    },
    editarItem() {
      store.dispatch('app-times/update', this.itemData)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Time atualizado com sucesso.',
              icon: 'CheckCircleIcon',
              variant: 'success',

            },
          })
          this.$router.push({ name: 'times' })
        })
    },
    getTime() {
      const idTime = this.$router.currentRoute.params.id
      const context = this
      store.dispatch('app-times/show', idTime)
        .then(respo => {
          context.itemData = respo.data
        })
    },
    tratarEventoUsuariosSelecionados(itens) {
      this.itemData.users = itens
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
