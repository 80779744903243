<template>
  <component :is="'b-card'">
    <edit-tab class="mt-1" />
  </component>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'

import store from '@/store'
import timeStoreModule from '../timeStoreModule'
import EditTab from './EditTab.vue'

export default {
  components: {
    BCard,
    EditTab,
  },
  setup() {
    const TIMES_APP_STORE_MODULE_NAME = 'app-times'

    // Register module
    if (!store.hasModule(TIMES_APP_STORE_MODULE_NAME)) store.registerModule(TIMES_APP_STORE_MODULE_NAME, timeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TIMES_APP_STORE_MODULE_NAME)) store.unregisterModule(TIMES_APP_STORE_MODULE_NAME)
    })
  },
}
</script>
